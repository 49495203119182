//
// COLOR
//
// MAIN
$primary: #0098D8;
$complementary: #FD5502;
$light-blue: #F6FAFD;
$prime: #C5A459;
$pbb: #0B61AC;
$pbb-hover: #06569C;

$primary-dark: #0487BF;
$primary-light: #0e96d20d;
$light-primary: #e0eff9;

// FONTS
$default-emphasis: #0F2439;
$medium-emphasis: #0F2439CC;
$light-emphasis: #0F243999;
$placeholder-emphasis: #0F243966;
$disabled-emphasis: #0F243933;

// PALETTE
$red: #EB4F2A;
$green: #59C560;

// GREY SCALE
$grey-20: #F2F2F2;
$white: #FFFFFF;

// BUTTONS
$primary-hover: #0e96d20d;

//
// LAYOUT
//
$gap-xxlarge: 80px;
$gap-xlarge: 72px;
$gap-large: 40px;
$gap-xmedium: 32px;
$gap-medium: 24px;
$gap-small: 16px;
$gap-xsmall: 8px;

//
// COMPONENT
//
$radius: 8px;

// 
// WIDTH
//
$min-block: 320px;
$min-tablet: 801px;

//
// FONT FAMILY
// 
$family-title: 'Shirens';
$family-pbb: 'Menda Medium';
$family-main: 'Roboto Medium';
$family-bold: 'Roboto Bold';
$family-condensed: 'Roboto Condensed';