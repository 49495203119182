@import '../../assets/styles/variables';

.membership-subscribe {
  display: flex;
  flex-direction: column;
  gap: $gap-small;
  align-items: center;

  .card {
    max-width: 350px;

    :not(:last-child) {
      margin-bottom: $gap-small;
    }

    a {
      color: $pbb;
    }
  }
}

.om-connect .membership h2 {
  font-family: $family-pbb;
  font-size: 0.95rem;
  text-transform: uppercase;
}

.decoration {
  height: 28px;
  .border {
    position: absolute;
    left: 0;
    width: 100%;
  }

  .dark-blue-border {
    height: 12px;
    background: #092C4D;
  }

  .primary-border {
    height: 12px;
    background: $pbb;
    margin-top: 4px;
  }
}

.membership {
  .title-header {
    margin-bottom: 32px;

    h1 {
      display: flex;
      justify-content: space-between;

      button {
        width: fit-content;
        text-wrap: nowrap;
        background-color: $pbb;
        border-color: $pbb;

        &:hover {
          background-color: $pbb-hover;
          border-color: $pbb-hover;
        }
      }
    }
  }

  .card-column {
    min-width: $min-block;
  }
  
  .membership-content {
    display: flex;
    flex-direction: column;
    gap: $gap-large;

    .accordion {
      pointer-events: none;

      @media only screen and (min-width: calc($min-tablet + 1px)) {
        .header::after {
          content: none;
        }
      }

      .content {
        padding-top: 16px;
      }
    }
  
    .member-account-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $gap-medium;
    
      .subheader {
        background-color: #cde3f4;
        border-radius: $radius;
        padding: $gap-small;
        font-size: 0.85em;
      }
    
      .qrcode {
        text-align: center;
        width: 88%;
    
        > *:first-child {
          padding-bottom: $gap-xsmall;
        }
    
        .frame {
          background:
            linear-gradient(to right, black 4px, transparent 4px) 0 0,
            linear-gradient(to right, black 4px, transparent 4px) 0 100%,
            linear-gradient(to left, black 4px, transparent 4px) 100% 0,
            linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
            linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
            linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
            linear-gradient(to top, black 4px, transparent 4px) 0 100%,
            linear-gradient(to top, black 4px, transparent 4px) 100% 100%;
          background-repeat: no-repeat;
          background-size: 20px 20px;
          text-align: center;
    
          img {
            width: 100%;
            padding: 4px;
          }
        }
      }

      button {
        background-color: $pbb;
        border-color: $pbb;
      }
    }

    .links svg {
      fill: $pbb;

      circle {
        stroke: $pbb;
        fill: $pbb;
      }
    }
  }

  #qr-container {
    padding: 10px;
  }

  .membership-block {
    display: flex;
    flex-direction: column;
    gap: $gap-small;

    .block-title {
      display: flex;
      align-items: center;
      gap: 12px;

      .circle {
        width: 8px;
        height: 8px;
        background-color: #FD5502;
        border-radius: 24px;
      }

      .block-count {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }

    .shortcuts-list {
      display: flex;
      justify-content: space-between;
      gap: $gap-small;
    }

    &.partners .partner .title {
      font-family: $family-bold;
      margin-bottom: 8px;
    }
  }

  .partner .partner_btn {
    color: $pbb;

    &:hover {
      color: $complementary;
    }
  }

  .club-offer .block-header {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 0;

    svg {
      height: 26px;
      width: auto;
      fill: $pbb;
    }

    .title {
      text-align: center;
      font-family: 'Roboto Condensed Bold';
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .links .bloc-link {
    padding: 16px 0;
    font-family: $family-bold;

    .link {
      cursor: pointer;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        color: $pbb-hover;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $placeholder-emphasis;
    }
  }
}

//
// RESPONSIVE
//
@media only screen and (max-width: $min-tablet) {
  .membership {
    .membership-content {
      gap: $gap-xmedium;

      .accordion {
        pointer-events: auto;
  
        .content {
          display: none;
          padding-top: 16px;
        }
  
        &.display {
          .content {
            display: block;
          }
        }
      }
    }

    .member-logo {
      width: 148px;
      margin: $gap-small 0;
    }
  }
}